import { FC, HTMLAttributes } from 'react';
import styles from './LinearLoader.module.scss';
import classNames from 'classnames';

interface Props extends HTMLAttributes<HTMLDivElement> {
  isLoading?: boolean;
};

const LinearLoader: FC<Props> = ({ isLoading, ...otherProps}) => {
  return (
    <div {...otherProps}>
      <div className={classNames(styles.container, isLoading && styles.busy)}>
        <div className={styles.indicator} />
      </div>
    </div>
  )
}

export default LinearLoader;