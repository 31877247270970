import { storyblokEditable } from '@storyblok/react';
import { FC } from 'react';
import { Blok } from '../../../types/bloks';
import styles from './TableBlock.module.scss';

interface BaseRow extends TableBlokData {
  value: string;
}

interface BodyRow extends TableBlokData {
  body: BaseRow[];
}

interface TableBlokData {
  title: string;
  table: {
    fieldtype: string;
    thead: BaseRow[];
    tbody: BodyRow[];
  }
}

interface Props {
  blok: Blok & TableBlokData;
}

const TableBlock: FC<Props> = ({ blok }) => {
  const title = blok.title;
  const headers = blok.table.thead
  const body = blok.table.tbody
      
  return (
    <div
      {...storyblokEditable(blok)} 
      key={blok._uid} 
      data-test="table"
      className="content"
    >
      <div className="max-w-4xl mx-auto">
        {!!title && <h4 className={styles.title}>{ title }</h4>}
        <table className={styles.table}>
          <thead>
            <tr>
              { headers.map((header) => <th className={styles.cell}>{header.value}</th>)}
            </tr>
          </thead>
          <tbody>
            { body.map((row) => (
              <tr className={styles.row}>
                { row.body.map((cell) => <td className={styles.cell}>{cell.value}</td>)}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default TableBlock;