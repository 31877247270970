import { StoryblokComponent, useStoryblok } from "@storyblok/react";
import classNames from "classnames";
import dayjs from "dayjs";
import { FC, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDebounce } from 'use-debounce';
import { ReactComponent as LogoMark } from '../../../assets/images/logo/mark.svg';
import LinearLoader from "../../basics/LinearLoader/LinearLoader";
import BaseLayout from "../../layouts/BaseLayout/BaseLayout";
import styles from './ContentFromSlugPage.module.scss';

interface Props {};

const ContentFromSlugPage: FC<Props> = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const story = useStoryblok(location.pathname.replace("/", ""), { version: "draft" });
  const [isLoading] = useDebounce(!story?.content || loading, 2000);
  const ref = useRef(location.pathname);
  
  useEffect(() => {
    console.log('WAS', ref.current, 'IS', location.pathname);
    if (ref.current !== location.pathname) {
      setLoading(true);
    } else {
      setLoading(false);
    }
    ref.current = location.pathname;
  }, [story?.full_slug, location.pathname]);

  if (isLoading) {
    return (
      <BaseLayout showFooter={false}>
        <div className="w-full h-full flex flex-col justify-center items-center">
          <div className="w-52">
            <LogoMark fill="currentColor" className="text-gray-300 mb-4 mx-auto" width={140} />
            <LinearLoader isLoading className="w-20 mx-auto" />
          </div>
        </div>
      </BaseLayout>
    );
  }
  
  const headerImage = story.content.header_image as any;
  const hasHeaderImage = !!headerImage.filename;
  // const storyCreateDate = dayjs(story.created_at).format("DD MMM YYYY")
  const storyLastPublishDate = dayjs(story.published_at).format("DD MMM YYYY")
  
  return (
    <BaseLayout>
      <div className={classNames(styles.wrapper, hasHeaderImage && styles.withHeaderImage)}>
        <div className={styles.pageHeader}>
          {hasHeaderImage && (
            <div className={styles.headerImageWrapper}>
              <img src={headerImage.filename} alt="header" className={styles.headerImage} />
            </div>
          )}
          <div className={styles.pageHeaderContent}>
            <h2 className={styles.pageTitle}>{ story.name }</h2>
            <p className={styles.storyDate}>Laatst aangepast {storyLastPublishDate}</p>
          </div>
        </div>
        <div className={styles.content}>
          <StoryblokComponent blok={story.content} />
        </div>
      </div>
    </BaseLayout>
  );
};

export default ContentFromSlugPage;
