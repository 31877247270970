import { storyblokEditable, SbBlokData } from "@storyblok/react";
import { FC } from "react";

interface Props {
  blok: SbBlokData;
}

const Teaser: FC<Props> = ({ blok }) => {
  return (
    <div {...storyblokEditable(blok)} key={blok._uid} data-test="teaser">
      <div>
        <h2>{blok.headline as string}</h2>
      </div>
    </div>
  );
};

export default Teaser;