import { SbBlokData, storyblokEditable } from "@storyblok/react";
import { FC } from "react";

interface Props {
  blok: SbBlokData;
};

const ImageBlock: FC<Props> = ({ blok }) => {
  return (
    <div
      {...storyblokEditable(blok)} 
      className="max-w-5xl  mx-auto"
      key={blok._uid} data-test="image"
    >
      { blok.source && <img src={(blok.source as any).filename} className="max-h-96 w-full object-cover" alt="" />}
    </div>
  )
};

export default ImageBlock;
