import { SbBlokData, storyblokEditable } from '@storyblok/react';
import { FC } from 'react';

interface Props {
  blok: SbBlokData;
};

const CalloutBlock: FC<Props> = ({ blok }) => {
  return (
    <div
      {...storyblokEditable(blok)} 
      className="max-w-4xl mx-auto"
      key={blok._uid} data-test="button"
    >
      
    </div>
  )
}

export default CalloutBlock;