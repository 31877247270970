import { SbBlokData, renderRichText, storyblokEditable } from '@storyblok/react';
import classNames from 'classnames';
import { FC } from 'react';
import styles from './Paragraph.module.scss';

interface Props {
  blok: SbBlokData;
}

const Paragraph: FC<Props> = ({ blok }) => {
  const renderedRichText = renderRichText(blok.content as any);

  return (
    <div 
      {...storyblokEditable(blok)} 
      className={classNames(styles.wrapper, 'content')}
      key={blok._uid} data-test="paragraph"
    >
      <div className="max-w-4xl mx-auto" dangerouslySetInnerHTML={{ __html: renderedRichText }} />
    </div>
  )
}

export default Paragraph;