import classNames from "classnames";
import { FC } from "react";
import { Link, LinkProps } from "react-router-dom";
import Icon from "../../../basics/Icon/Icon";
import { IconProps } from "../../../basics/Icon/Icon.types";
import styles from './AsideMenuItem.module.scss';

interface Props extends LinkProps {
  active: boolean;
  label: string;
  icon: IconProps['name'];
}

const AsideMenuItem: FC<Props> = ({ label, to, icon, active, className, ...otherProps }) => {
  return (
    <Link className={classNames(styles.item, active && styles.active, className)} to={to} {...otherProps}>
      <Icon name={icon} size="base" />
      <div className={styles.labelWrapper}>
        <span className={styles.label}>{ label }</span>
      </div>
      <Icon className={styles.arrow} name="arrow-right-s-line" size="base" />
    </Link>
  );
};

export default AsideMenuItem;
