import { FC } from 'react';
import remix from 'remixicon/fonts/remixicon.symbol.svg';
import { IconProps } from './Icon.types';
import Svg from './Svg';

/**
 * 
 * @description Icons are from [Remixicons](https://remixicon.com/)
 */
const Icon: FC<IconProps> = ({ color = 'currentColor', size = 'base', fill, noStyle, name = 'admin', className, legacy }) => {
    const nameAndStyleSeperator = legacy ? noStyle ? '' : '-' : undefined;
    const iconStyle = legacy ? fill ? 'fill' : 'line' : undefined;
    const iconName = legacy ? `${ name }${ nameAndStyleSeperator }${ iconStyle }` : name;
    
    return (
        <Svg
            { ...{ color, size, className }}
        >
            <use href={ remix + `#ri-${ iconName }`}></use>
        </Svg>
    )
}

export default Icon