import classNames from 'classnames';
import dayjs from 'dayjs';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import Icon from '../Icon/Icon';
import styles from './Footer.module.scss';

interface Props {};

const Footer: FC<Props> = () => {
  return (
    <>
      <footer className={styles.footer}>
        <div className="max-w-4xl mx-auto">
          <div className={styles.content}>
            <div className={styles.start}>
              <span>&copy; 2007 - {dayjs().format('YYYY')} Gouw Gent</span>
              <span className={classNames(styles.separator, 'text-gray-400')}>|</span>
              <a 
                href="https://www.scoutsengidsenvlaanderen.be/publicaties/statuut-van-de-vrijwilliger" 
                target="_blank" 
                rel="noreferrer"
                className="flex items-center gap-1 text-gray-400"
              >
                <span>Statuut van de vrijwilliger</span>
                <Icon name="arrow-right-line" size="small" />
              </a>
              <span className={classNames(styles.separator, 'text-gray-400')}>|</span>
              <Link to="/privacy" className="flex items-center gap-1 text-gray-400">
                <span>Privacy</span>
                <Icon name="arrow-right-line" size="small" />
              </Link>
            </div>
            <div className={styles.credit}>
              <a href="https://www.jung.gent" target="_blank" rel="noreferrer" className="text-gray-400">Ontwikkeld door JUNG&nbsp;･&nbsp;Gent</a>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer;