import { SbBlokData, storyblokEditable } from '@storyblok/react';
import normalizeUrl from 'normalize-url';
import { FC, useMemo } from 'react';
import BaseButton from '../../basics/Button/BaseButton/BaseButton';

interface Props {
  blok: SbBlokData;
};

const ButtonBlock: FC<Props> = ({ blok }) => {
  const label = blok?.label as string;
  const linkData = blok?.href as any;
  const type = linkData?.linktype;
  const target = (linkData?.target || '_self') as string;
  
  const protocol = useMemo(() => {
    if (type === 'email') return 'mailto:';
    else return '';
  }, [type]);
  
  const href = normalizeUrl([protocol, linkData.cached_url].join(''));
  
  return (
    <div
      {...storyblokEditable(blok)} 
      className="max-w-4xl mx-auto"
      key={blok._uid} data-test="button"
    >
      <a href={href} target={target}>
        <BaseButton iconAfter="arrow-right-line">
          { label }
        </BaseButton>
      </a>
    </div>
  )
}

export default ButtonBlock;