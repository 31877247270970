import { SbBlokData, storyblokEditable } from '@storyblok/react';
import { FC } from 'react';

interface Props {
  blok: SbBlokData;
};

const PersonBlock: FC<Props> = ({ blok }) => {
  const name = blok.name as string;
  
  return (
    <div
      {...storyblokEditable(blok)} 
      key={blok._uid} data-test="person"
    >
      {name}
    </div>
  )
}

export default PersonBlock;