import { FC, PropsWithChildren } from 'react';
import Footer from '../../basics/Footer/Footer';
import AsideMenu from '../../elements/AsideMenu/AsideMenu';

interface Props extends PropsWithChildren {
  showMenu?: boolean;
  showFooter?: boolean;
};

const BaseLayout: FC<Props> = ({ children, showMenu = true, showFooter = true }) => {
  return (
    <div className="h-full flex">
      {showMenu && <AsideMenu />}
      <div className="flex-1 flex flex-col">
        <div className="flex-1">
          { children }
        </div>
        {showFooter && (
          <div className="bg-gray-50 mt-8">
            <Footer />
          </div>
        )}
      </div>
    </div>
  )
}

export default BaseLayout;