import { SbBlokData, storyblokEditable } from '@storyblok/react';
import { FC, useCallback } from 'react';
import Icon from '../../basics/Icon/Icon';
import styles from './CardBlock.module.scss';

interface Props {
  blok: SbBlokData;
};

const CardBlock: FC<Props> = ({ blok }) => {
  const cardImage = (blok.feature_image as any).filename;
  const link = (blok.link as any).cached_url as string;
  const hasLink = !!link ? true : null;
  const linkLabel = !!(blok.link_label as any) ? blok.link_label as string : 'Meer lezen';
  const linkType = (blok.link as any).linktype === 'email' ? 'mailto:' : '';
  
  const Wrapper = useCallback<FC<any>>((props: any) => {
    if (hasLink) {
      return <a href={linkType + link} {...props} />
    } else return <div {...props} />
  }, [hasLink, link, linkType])
  
  return (
    <Wrapper
      {...storyblokEditable(blok)}
      key={blok._uid} 
      className={styles.container}
    >
      {!!cardImage && <img className={styles.featureImage} src={cardImage} alt="" />}
      <h4 className={styles.title}>{blok.title as string}</h4>
      <p className={styles.subtitle}>{blok.subtitle as string}</p>
      {!!hasLink && <div className={styles.link}>
        {linkLabel}
        <Icon name="arrow-right-up-line" size="small" className={styles.linkIcon} />
      </div>}
    </Wrapper>
  )
}

export default CardBlock;