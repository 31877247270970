import {
  SbBlokData,
  StoryblokComponent,
  storyblokEditable
} from "@storyblok/react";
import { FC } from "react";
import styles from './Page.module.scss';

interface Props {
  blok: SbBlokData;
}

const Page: FC<Props> = ({ blok }) => {
  return (
    <div className={styles.container} {...storyblokEditable(blok)} key={blok._uid}>
      <div className={styles.content}>
        {blok?.body && (blok?.body as SbBlokData[])?.map((nestedBlok) => (
          <div key={nestedBlok._uid} className={styles.section}>
            <StoryblokComponent blok={nestedBlok} />
          </div>
        ))}
      </div>
    </div>
  )
};

export default Page;