import { ButtonHTMLAttributes, FC, useMemo } from 'react';
import { LinkProps } from 'react-router-dom';
import { AvailableIcons } from '../Icon/Icon.types';
import BaseButton from './BaseButton/BaseButton';

interface Props {
  variant?: 'outline' | 'underline';
  iconAfter?: AvailableIcons;
  className?: string;
};

type P = Props & LinkProps;
type D = Props & LinkProps;

type H = Props & { to?: never; } & ButtonHTMLAttributes<HTMLButtonElement>;

const Button: FC<P | D | H> = ({ className, children, variant = 'outline', iconAfter, to, ...otherProps }) => {
  const Wrapper = useMemo(() => {
    switch (true) {
      case Boolean(to):
        return require('react-router-dom').Link;
      default:
        return 'button';
    }
  }, [to]);
  
  return (
    <>
      <Wrapper className={className} to={to} {...otherProps}>
        <BaseButton variant={variant} iconAfter={iconAfter}>
          { children }
        </BaseButton>
      </Wrapper>
    </>
  )
}

export default Button;